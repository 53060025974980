export const careerSteps = [
  {
    id: '01',
    title: 'Gegenseitiges Kennenlernen',
    description:
      'Nachdem wir Ihre Bewerbung erhalten haben, werden wir uns mit Ihnen in Verbindung setzen, um ein Orientierungsgespräch mit unserem HR-Team zu vereinbaren.'
  },
  {
    id: '02',
    title: 'Technische Aufgabe',
    description:
      'Damit wir Ihre technischen Fähigkeiten besser einschätzen können, geben wir Ihnen eine kurze Aufgabe, die Sie in Ihrem eigenen Arbeitsumfeld und zu Ihrer eigenen Zeit erledigen müssen.'
  },
  {
    id: '03',
    title: 'Treffen Sie den technischen Leader',
    description:
      'Während des zweiten Vorstellungsgesprächs treffen Sie unseren technischen Leiter, um Ihre technischen Fähigkeiten zu bewerten und über Ihren technischen Hintergrund und Ihre bisherigen Erfahrungen zu sprechen.'
  },
  {
    id: '04',
    title: 'Vorbereitung auf Ihre neue Stelle',
    description:
      'Nach dem erfolgreichen technischen Vorstellungsgespräch beginnen wir mit der Vorbereitung auf Ihre neue Stelle.'
  },
  {
    id: '05',
    title: 'Beginn der Zusammenarbeit',
    description: 'Ihre berufliche Reise mit the.good.code; beginnt hier!'
  }
]

export const ourCommitmentContent = [
  {
    title: 'Wir sind Kollegen',
    desc: 'Wir betrachten jeden Kandidat als zukünftigen Kollegen.'
  },
  {
    title: 'Wachstum',
    desc: 'Ein Team von Fachleuten, die Sie unterstützen und Ihnen das richtige Feedback geben, um Ihre berufliche Entwicklung zu fördern, macht Sie erfolgreich.'
  },
  {
    title: 'Gleichheit und Verschiedenheit',
    desc: 'Wir bei the.good.code sind der Meinung, dass Gleichstellung und Vielfalt am Arbeitsplatz eine wesentliche Voraussetzung für die Schaffung eines positiven und integrativen Arbeitsumfelds sind, in dem sich alle Mitarbeiter entfalten und ihr volles Potenzial ausschöpfen können.'
  },
  {
    title: 'Unterstützung',
    desc: 'Wir möchten sicherstellen, dass Sie bei jedem Schritt des Einstellungsverfahrens unterstützt werden und die getroffene Entscheidung verstehen.'
  },
  {
    title: 'Richtige Chancen',
    desc: 'Wir helfen Kandidaten, eine fundierte Entscheidung zu treffen.\n'
  },
  {
    title: 'Unser Ziel',
    desc: 'Es geht darum, transparent und kooperativ zu sein und jedem Teammitglied den Erfolg zu ermöglichen, während wir gemeinsam mit uns den Weg des kontinuierlichen Wachstums gehen.'
  }
]
