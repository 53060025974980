import React from 'react'
import teamPhoto from '../../../images/careers/teamPhoto.jpg'
import ContentContainer from '../Layout/ContentContainer'

const TeamPhoto = () => (
  <ContentContainer className='translate-y-12 relative z-10'>
    <img
      src={teamPhoto}
      alt='team'
      className='rounded-xl'
    />
  </ContentContainer>
)

export default TeamPhoto
