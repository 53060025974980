import React from 'react'
import ContentContainer from '../Layout/ContentContainer'
import PropTypes from 'prop-types'
import { Cursor, Typewriter } from 'react-simple-typewriter'

const SectionHeader = ({ className, wordsToType, loop, text }) => (
  <ContentContainer
    as='header'
    className={className}
  >
    <h1 className='relative w-5/6 my-10 lg:my-20'>
      {text}
      <span className='after:text-amber-300'>
        <Typewriter
          words={wordsToType}
          loop={loop}
          typeSpeed={100}
          deleteSpeed={50}
          delaySpeed={1000}
        />
        <Cursor
          cursorColor='#01DD93'
          cursorStyle='/'
        />
      </span>
    </h1>
  </ContentContainer>
)

SectionHeader.propTypes = {
  className: PropTypes.string,
  wordsToType: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  loop: PropTypes.number,
  text: PropTypes.string.isRequired
}

SectionHeader.defaultProps = {
  className: '',
  loop: 1,
  text: ''
}

export default SectionHeader
