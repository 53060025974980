import React from 'react'
import Layout from '../../components/de/Layout/Layout'
import CareersPage from '../../components/de/Careers/CareersPage'

const Careers = () => (
  <Layout
    title='Werden Sie Mitglied unseres Teams | the.good.code; - Spannende Projekte für talentierte IT-Fachleute'
    description='Sind Sie ein talentierter und leidenschaftlicher IT-Profi? Werden Sie Teil des.good.code; Teams und arbeiten Sie an spannenden Projekten für globale Kunden. Durchsuchen Sie unsere offenen Stellen und bewerben Sie sich noch heute.'
    content='Wir sind immer auf der Suche nach talentierten und leidenschaftlichen Menschen, die unser Team verstärken möchten. Kontaktieren Sie uns:'
    mail='hr@thegoodcode.io'
  >
    <CareersPage />
  </Layout>
)

export default Careers
