import React from 'react'

const Quote = () => (
  <svg
    width='56'
    height='45'
    viewBox='0 0 56 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.2392 -0.000244141V8.99976H16.0192C11.6992 8.99976 9.53922 11.1597 9.53922 15.4797V21.24H12.0592C15.28 21.1427 18.4118 22.3048 20.7892 24.4797C21.9235 25.4971 22.8245 26.7477 23.4303 28.1458C24.0362 29.5438 24.3326 31.0565 24.2992 32.5798C24.3563 34.1594 24.0723 35.7327 23.4666 37.1926C22.8609 38.6525 21.9477 39.9646 20.7892 41.0398C18.4118 43.2147 15.28 44.3773 12.0592 44.28C10.4585 44.3303 8.86447 44.051 7.3759 43.4602C5.88734 42.8694 4.53593 41.9793 3.40535 40.845C2.27478 39.7106 1.38912 38.3565 0.803227 36.866C0.217335 35.3755 -0.0563154 33.7805 -0.00077406 32.1799V15.4797C-0.00077406 5.15974 5.15922 -0.000244141 15.4792 -0.000244141H21.2392ZM52.5592 -0.000244141V8.99976H47.3392C43.0192 8.99976 40.8592 11.1597 40.8592 15.4797V21.24H43.4192C46.64 21.1427 49.7718 22.3048 52.1492 24.4797C53.2835 25.4971 54.1845 26.7477 54.7903 28.1458C55.3962 29.5438 55.6926 31.0565 55.6592 32.5798C55.7163 34.1594 55.4323 35.7327 54.8266 37.1926C54.2209 38.6525 53.3077 39.9646 52.1492 41.0398C49.7718 43.2147 46.64 44.3773 43.4192 44.28C41.8152 44.3359 40.2168 44.0612 38.7235 43.4729C37.2302 42.8846 35.8739 41.995 34.739 40.8601C33.6041 39.7252 32.7148 38.369 32.1265 36.8757C31.5381 35.3824 31.2634 33.784 31.3192 32.1799V15.4797C31.3192 5.15974 36.4792 -0.000244141 46.7992 -0.000244141H52.5592Z'
      fill='#01DD93'
    />
  </svg>
)

export default Quote
