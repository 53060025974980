import React from 'react'
import SectionHeader from '../../common/Header/SectionHeader'
import ContentContainer from '../../common/Layout/ContentContainer'
import TgcLink from '../../common/TgcLink/TgcLink.jsx'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

const query = graphql`
  query {
    backgroundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "careers/careersHeader.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

const CareersHeader = () => {
  const { backgroundImage } = useStaticQuery(query)
  const image = getImage(backgroundImage)
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag='div'
      className='h-max text-brand-secondary'
      preserveStackingContext
      {...bgImage}
    >
      <div className='pt-20 pb-20'>
        <SectionHeader wordsToType={['Karriere']} />
        <ContentContainer>
          <p className='text-4xl leading-snug font-light lg:text-6xl'>
            Wir machen den Einstellungsprozess mit the.good.code einfach und
            transparent, um sicherzustellen, dass jeder Kandidat gut informiert
            und auf die kommenden Schritte vorbereitet ist.
          </p>
          <div className='my-20'>
            <TgcLink href='https://zest-expansion-c6b.notion.site/Open-Positions-with-the-good-code-b83b97611e654d31a281f945660bb343'>
              Offene Stellen
            </TgcLink>
          </div>
        </ContentContainer>
      </div>
    </BackgroundImage>
  )
}

export default CareersHeader
