import React from 'react'
import TextWithDots from '../../common/TextWithDots/TextWithDots'
import { ourCommitmentContent } from './consts'
import ContentContainer from '../../common/Layout/ContentContainer'
import TgcLink from '../../common/TgcLink/TgcLink'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation.jsx'

const OurCommitment = () => (
  <section className='bg-brand-secondary article-padding'>
    <ContentContainer>
      <FadeInUpAnimation>
        <TextWithDots
          className='pb-20 lg:text-6xl text-4xl font-normal'
          as='h4'
        >
          Unser Commitment
        </TextWithDots>
      </FadeInUpAnimation>
      <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-10'>
        {ourCommitmentContent.map((content, index) => (
          <FadeInUpAnimation
            key={content.title}
            delay={index / 4}
          >
            <div className='border-t border-line-grey md:mb-20 mb-5'>
              <p className='py-5 2xl:text-5xl text-4xl lg:leading-loose leading-2'>
                {content.title}
              </p>
              <p className='text-xl leading-7'>{content.desc}</p>
            </div>
          </FadeInUpAnimation>
        ))}
      </div>
      <FadeInUpAnimation>
        <div className='mt-20'>
          <TgcLink href='https://zest-expansion-c6b.notion.site/Open-Positions-with-the-good-code-b83b97611e654d31a281f945660bb343'>
            Offene Stellen
          </TgcLink>
        </div>
      </FadeInUpAnimation>
    </ContentContainer>
  </section>
)

export default OurCommitment
