import React from 'react'
import ContentContainer from '../../common/Layout/ContentContainer'
import CareersHeader from './CareersHeader'
import CareersJourney from './CareersJourney'
import OurCommitment from './OurCommitment'
import InspiringMsg from './InspiringMsg'
import TeamPhoto from '../../common/Careers/TeamPhoto'

const CareersPage = () => (
  <>
    <CareersHeader />
    <ContentContainer>
      <CareersJourney />
    </ContentContainer>
    <OurCommitment />
    <InspiringMsg />
    <TeamPhoto />
  </>
)

export default CareersPage
