import React, { useEffect, useRef } from 'react'
import FadeInUpAnimation from '../FadeInUpAnimation/FadeInUpAnimation.jsx'
import PropTypes from 'prop-types'
import { gsap } from '../../gsap'

const CareerStep = ({ id, title, description }) => {
  const stepRef = useRef(null)
  const lineRef = useRef(null)

  useEffect(() => {
    const step = stepRef.current
    const line = lineRef.current

    const ctx = gsap.context(() => {
      gsap.from(step, {
        opacity: 0.2,
        scrollTrigger: {
          trigger: step,
          start: 'top 70%',
          end: 'top 20%'
        }
      })

      gsap.from(line.children[0], {
        height: 0,
        scrollTrigger: {
          trigger: line,
          scrub: true,
          start: 'top 55%',
          end: 'bottom 55%'
        }
      })
    }, stepRef)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <FadeInUpAnimation>
      <div
        className='flex gap-12 my-2'
        ref={stepRef}
      >
        <div className='flex flex-col items-center'>
          <div>
            <div className='w-5 h-5 rounded-full bg-brand-primary' />
          </div>
          <div
            className='h-full w-0.5 bg-brand-primary/20 mt-2'
            ref={lineRef}
          >
            <span className='block h-full w-full bg-brand-primary' />
          </div>
        </div>
        <div className='md:mb-36 mb-20 md:-mt-3 -mt-2'>
          <p className='text-brand-primary lg:text-5xl text-2xl font-light leading-normal'>
            {id}.
          </p>
          <h4 className='lg:text-5xl md:text-3xl text-2xl font-light leading-7'>
            {title}
          </h4>
          <p className='my-6'>{description}</p>
        </div>
        <div className='h-full w-0.5 bg-brand-primary mt-2'></div>
      </div>
    </FadeInUpAnimation>
  )
}

export default CareerStep

CareerStep.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

CareerStep.defaultProps = {
  id: '',
  title: '',
  description: ''
}
